export default {
  data() {
    return {
      isGet: 1,
      iconSize: "14px",
      vanDirection: "horizontal",
      checkedColor: "green",
      isNotMobile: false,
      my_first_name: "",
      nameType: "人名",
      mainHeight: "",
      name: "",
      sex: "帅哥",
      first_name: "单姓",
      last_name: "单字名",
      tags: ["帅哥", "美女"],
      gameNameType: "小白",
      tags1: ["非主流", "文艺", "年代感", "小白", "文青"],
      tags2: ["猫咪", "修狗"],
      constellationImgs1: [{
        src: require("../assets/水瓶座.png"),
        name: "水瓶座"
      }, {
        src: require("../assets/双鱼座.png"),
        name: "双鱼座"
      }, {
        src: require("../assets/白羊座.png"),
        name: "白羊座"
      }, {
        src: require("../assets/金牛座.png"),
        name: "金牛座"
      }, {
        src: require("../assets/双子座.png"),
        name: "双子座"
      }, {
        src: require("../assets/巨蟹座.png"),
        name: "巨蟹座"
      }],
      constellationImgs2: [{
        src: require("../assets/狮子座.png"),
        name: "狮子座"
      }, {
        src: require("../assets/处女座.png"),
        name: "处女座"
      }, {
        src: require("../assets/天秤座.png"),
        name: "天秤座"
      }, {
        src: require("../assets/天蝎座.png"),
        name: "天蝎座"
      }, {
        src: require("../assets/射手座.png"),
        name: "射手座"
      }, {
        src: require("../assets/摩羯座.png"),
        name: "摩羯座"
      }],
      tags3: ["好名字都被狗吃了", "好名字都被狗取了", "ID不存在", "我命由我不由天", "轻舟已过万重山", "一人一海", "此ID已被注册", "奥特曼与小怪兽二三事"],
      tags4: ["没心动该多好!", "后来你不在", "错过", "再见，再也不见", "缘来是你"],
      tags5: ["豆包", "二狗", "大橘", "二呆", "八戒", "串串", "铁蛋", "狗蛋"],
      tags6: ["杰", "瑞", "睿", "刚", "徽", "军", "俊", "峰", "志", "墨", "翔", "天", "飞", "辉", "子", "华", "毅", "兵", "凯", "冲", "全", "胜", "宇", "坤", "伟", "昊", "浩", "皓", "玉", "靖", "腾", "海", "风", "山", "筱"],
      tags7: ["柔", "谷", "柒", "桑", "涵", "冉", "汐", "熙", "嫣", "缦", "绮", "妮", "曼", "琇", "莹", "惜", "珺", "玲", "夏", "丹", "琼", "蕊", "筱", "琳", "霖", "龄", "雪", "梅", "雅", "娅", "琴", "婷", "萱", "轩", "梦", "颍", "紫", "丽", "彩", "晶", "静", "婧", "娟", "婉", "茹", "美", "灵", "慧", "会", "真", "珍", "子"],
      tags8: ["欧阳", "太史", "端木", "上官", "司马", "东方", "独孤", "南宫", "万俟", "闻人", "夏侯", "诸葛", "尉迟", "公羊", "赫连", "澹台", "皇甫", "宗政", "濮阳", "公冶", "太叔", "申屠", "公孙", "慕容", "仲孙", "钟离", "长孙", "宇文", "司徒", "鲜于", "司空", "闾丘", "子车", "亓官", "司寇", "巫马", "公西", "颛孙", "壤驷", "公良", "漆雕", "乐正", "宰父", "谷梁", "拓跋", "夹谷", "轩辕", "令狐", "段干", "百里", "呼延", "东郭", "南门", "羊舌", "微生", "公户", "公玉", "公仪", "梁丘", "公仲", "公上", "公门", "公山", "公坚", "左丘", "公伯", "西门", "公祖", "第五", "公乘", "贯丘", "公皙", "南荣", "东里", "东宫", "仲长", "子书", "子桑", "即墨", "达奚", "褚师"],
      tags9: ["李", "王", "张", "刘", "陈", "杨", "黄", "赵", "周", "吴", "徐", "孙", "马", "胡", "乔", "郭", "林", "何", "高", "梁", "佟", "郑", "罗", "宋", "安", "谢", "唐", "韩", "曹", "许", "邓", "萧", "冯", "曾", "程", "蔡", "彭", "潘", "袁", "于", "董", "余", "苏", "叶", "吕", "魏", "蒋", "田", "杜", "丁", "沈", "姜", "范", "江", "傅", "钟", "卢", "汪", "戴", "崔", "任", "陆", "廖", "姚", "方", "金", "邱", "夏", "谭", "韦", "贾", "邹", "石", "熊", "孟", "秦", "阎", "薛", "侯", "雷", "白", "龙", "段", "郝", "孔", "邵", "史", "毛", "常", "万", "顾", "赖", "武", "康", "贺", "严", "尹", "钱"],
      tags10: ["↖ィ", "╰☆╮", "じ☆ve", "厶。", "≧▽≦", "の╄→", "じò ぴé╰"],
      tags11: ["囙忆↘蕞镁╮", "︴饥饿萌≧▽≦", "硪の独有笨伯", "蓝色の幻想", "灞占迩の薀柔╰", "旧梦璀璨っ", "ぎ雨のぶ茚誋╰☆╮", "〆→偏執の守χμ者", "泪化作の雨", "の、贰玥眼号", "曽紟の詤言", "释怀の凹凸曼"]
    };
  },
  created() {
    this.getInit();
  },
  methods: {
    getInit() {
      this.mainHeight = window.innerHeight + "px";
      this.isNotMobile = !navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    },
    //随机数取一个名字
    // getName() {
    //   var timerId = setTimeout(function () {
    //     console.log("1秒钟到了");
    //     clearTimeout(timerId);
    //   }, 1000);
    // },

    random(length) {
      return Math.round(Math.random() * length);
    },
    async copyName() {
      if (this.name) {
        // navigator.clipboard.writeText(this.resourceContent).then(() => {
        //   this.successMsgBox('复制成功')
        // })
        // navigator clipboard 需要https等安全上下文
        if (navigator.clipboard && window.isSecureContext) {
          // navigator clipboard 向剪贴板写文本
          navigator.clipboard.writeText(this.name);
          this.successMsgBox("复制成功");
        } else {
          // 创建text area
          let textArea = document.createElement("textarea");
          textArea.value = this.name;
          // 使text area不在viewport，同时设置不可见
          textArea.style.position = "absolute";
          textArea.style.opacity = 0;
          textArea.style.left = "-999999px";
          textArea.style.top = "-999999px";
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          //异步编程，取代回调函数的一个东西
          return new Promise((res, rej) => {
            // 执行复制命令并移除文本框
            document.execCommand("copy") ? res("复制成功") : rej("复制失败");
            textArea.remove();
          }).then(res => {
            // 从 resolve 获取正常结果
            console.log(res);
            this.successMsgBox(res);
          }).catch(res => {
            // 从 reject 获取异常结果
            console.log(res);
            this.successMsgBox(res);
          });
        }
      }
    },
    getName() {
      this.isGet = 2;
      let newName = "";
      this.name = "";
      if (this.nameType == "特殊符号") {
        newName = this.tags10[this.random(this.tags10.length - 1)];
      } else if (this.nameType == "游戏名") {
        if (this.gameNameType == "非主流") {
          //非主流
          newName = this.tags11[this.random(this.tags11.length - 1)];
        } else {
          newName = this.tags3[this.random(this.tags3.length - 1)];
        }
      } else if (this.nameType == "宠物名") {
        newName = this.tags5[this.random(this.tags5.length - 1)];
      } else if (this.nameType == "人名") {
        if (this.first_name == "复姓") {
          if (this.sex == "帅哥") {
            if (this.last_name == "单字名") {
              newName = this.tags8[this.random(this.tags8.length - 1)] + this.tags6[this.random(this.tags6.length - 1)];
            } else {
              newName = this.tags8[this.random(this.tags8.length - 1)] + this.tags6[this.random(this.tags6.length - 1)] + this.tags6[this.random(this.tags6.length - 1)];
            }
          } else {
            if (this.last_name == "单字名") {
              newName = this.tags8[this.random(this.tags8.length - 1)] + this.tags7[this.random(this.tags7.length - 1)];
            } else this.name = this.tags8[this.random(this.tags8.length - 1)] + this.tags7[this.random(this.tags7.length - 1)] + this.tags7[this.random(this.tags7.length - 1)];
          }
        } else if (this.first_name == "单姓") {
          if (this.sex == "帅哥") {
            if (this.last_name == "单字名") {
              newName = this.tags9[this.random(this.tags9.length - 1)] + this.tags6[this.random(this.tags6.length - 1)];
            } else {
              newName = this.tags9[this.random(this.tags9.length - 1)] + this.tags6[this.random(this.tags6.length - 1)] + this.tags6[this.random(this.tags6.length - 1)];
            }
          } else {
            if (this.last_name == "单字名") {
              newName = this.tags9[this.random(this.tags9.length - 1)] + this.tags7[this.random(this.tags7.length - 1)];
            } else {
              newName = this.tags9[this.random(this.tags9.length - 1)] + this.tags7[this.random(this.tags7.length - 1)] + this.tags7[this.random(this.tags7.length - 1)];
            }
          }
        } else if (this.first_name == "自己选") {
          if (!this.my_first_name) {
            this.errorMsgBox("您还没输入姓！");
            return false;
          }
          if (this.sex == "帅哥") {
            if (this.last_name == "单字名") {
              newName = this.my_first_name + this.tags6[this.random(this.tags6.length - 1)];
            } else {
              newName = this.my_first_name + this.tags6[this.random(this.tags6.length - 1)] + this.tags6[this.random(this.tags6.length - 1)];
            }
          } else {
            if (this.last_name == "单字名") {
              newName = this.my_first_name + this.tags7[this.random(this.tags7.length - 1)];
            } else {
              newName = this.my_first_name + this.tags7[this.random(this.tags7.length - 1)] + this.tags7[this.random(this.tags7.length - 1)];
            }
          }
        }
      }
      setTimeout(() => {
        this.isGet = 3;
        this.name = newName;
      }, 1000);
    }
  }
};